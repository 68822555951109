/*Border*/

.border-bottom-1 {
  border-bottom: 1px solid var(--msflm-gray-color);
}

.border-top-1 {
  border-top: 1px solid var(--msflm-gray-color);
}

.border-left-1 {
  border-left: 1px solid var(--msflm-gray-color);
}

.border-right-1 {
  border-right: 1px solid var(--msflm-gray-color);
}

.border-bottom-2 {
  border-bottom: 2px solid var(--msflm-gray-color);
}

.border-top-2 {
  border-top: 2px solid var(--msflm-gray-color);
}

.border-left-2 {
  border-left: 2px solid var(--msflm-gray-color);
}

.border-right-2 {
  border-right: 2px solid var(--msflm-gray-color);
}
