.form-group .form-control,
.form-group .custom-select {
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--msflm-text-dark-color);
  border: none;
  border-radius: 0;
}

.form-group input.form-control,
.form-group select.form-control,
.form-group .custom-select {
  height: 38px;
  border-radius: 4px;
  background-color: hsl(0, 0%, 100%);
  border: 1px solid hsl(0, 0%, 80%);
}

.form-group textarea.form-control {
  border-radius: 4px;
  background-color: hsl(0, 0%, 100%);
  border: 1px solid hsl(0, 0%, 80%);
}

.form-group .custom-multiple-select,
.form-group .custom-multiple-select .ms__control {
}

.form-group .form-control-border {
  border: 1px solid var(--msflm-border-gray-color);
  box-sizing: border-box;
}

.form-group > input.form-control,
.form-group > select.custom-select {
  min-height: 38px;
  min-width: 140px;
}

.form-control[active] {
  background: #f8f8ff;
  color: var(--msflm-text-dark-color);
}

.form-group .form-control:not([disabled]):focus,
.input-group .form-control:not([disabled]):focus,
.input-group .form-control-sm:not([disabled]):focus,
.input-group .form-control-lg:not([disabled]):focus,
.form-group .form-control:not([disabled]):focus,
.form-group .form-control-plaintext:not([disabled]):focus {
  outline: 0;
  box-shadow: none;
  border: 1px solid var(--msflm-border-gray-color);
  background: #f8f8ff;
}

.input-group .input-group-prepend.btn,
.input-group .input-group-append.btn {
  border-radius: 0 !important;
}

.input-group-append .btn,
.input-group-prepend .btn {
  z-index: 0;
}

.input-group .input-group-prepend.input-group-prepend-border,
.input-group .input-group-append.input-group-append-border {
  border-radius: 0 !important;
  border: 1px solid var(--msflm-border-gray-color);
  padding: 0 10px;
}

.input-group-prepend .dropdown-toggle {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.input-group-append .dropdown-toggle {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.form-control .input-search {
}

.form-group > label.required:after {
  content: "*";
  font-weight: bold;
  color: var(--msflm-text-danger-color);
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: var(--msflm-text-gray-color) !important;
  opacity: 0.6 !important;
}

:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: var(--msflm-text-gray-color) !important;
  opacity: 0.6 !important;
}

::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: var(--msflm-text-gray-color) !important;
  opacity: 0.6 !important;
}

:-ms-input-placeholder { /* Internet Catalog 10-11 */
  color: var(--msflm-text-gray-color) !important;
  opacity: 0.6 !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: var(--msflm-text-gray-color) !important;
  opacity: 0.6 !important;
}

::placeholder { /* Most modern browsers support this now. */
  color: var(--msflm-text-gray-color) !important;
  opacity: 0.6 !important;
}

.form-control.is-invalid,
.custom-select.is-invalid {
  border: 1px solid var(--msflm-border-danger-color);
  color: var(--msflm-text-danger-color);
}

.form-control.is-valid {
  border: 1px solid var(--msflm-border-success-color);
  color: var(--msflm-text-success-color);
}