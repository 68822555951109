.schedule-container .sidebar {
  margin: 0;
  padding: 0 0 50px 0;
  width: 250px;
  position: fixed;
  height: calc(100vh - 200px) !important;
  border-right: 1px solid #ced4da;
}

.schedule-container .sidebar .sidebar-nav-list {
  padding-top: 10px;
  margin-top: 10px;
  height: calc(100vh - 250px) !important;
  overflow: auto;
}

.schedule-container .schedule-content {
  margin-left: 250px;
  padding: 0 0 50px 20px;
}

.schedule-container .footer {
  position: fixed;
  height: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 0;
}

@media screen and (max-width: 700px) {
  .schedule-container .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }

  .schedule-container .sidebar a {
    float: left;
  }

  div.content {
    margin-left: 0;
  }
}

@media screen and (max-width: 400px) {
  .schedule-container .sidebar a {
    text-align: center;
    float: none;
  }
}
